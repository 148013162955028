<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Task</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <table class="table" v-if="quote">
              <tbody>
                  <tr v-for="(v, k, index) in quote" :key="index" v-if="k !== 'id' && k !== 'user_id' && k !== 'status_pretty' && k !== 'options' && k !== 'assigned_to_pretty' && k !== 'due_date_formatted'">
                      <th>{{ k|capitalize }}</th>
                      <td v-if="k === 'assigned_to'">
                        <b-form-select v-model="model.user" :options="options.users"  :state="validateState(k)"></b-form-select>
                      </td>
                      <td v-else-if="k === 'status'">
                        <b-form-select v-model="model.status" :options="options.status"  :state="validateState(k)"></b-form-select>
                      </td>
                      <td v-else-if="k === 'due_date'">
                        <b-form-datepicker 
            :min="min"
            locale="en" v-model="model.due_date" :state="validateState(k)"></b-form-datepicker>
                      </td>
                      <td v-else>{{ v }}</td>
                  </tr>
              </tbody>
          </table>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="pull-left">Back</b-button>
          <b-button @click="Submit" variant="outline-success" class="float-right mr-2">Submit</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>
  </div>
</template>

<script>
  import axios from '../../../shared/axios/auth'
  import { validationMixin } from 'vuelidate'
  import { required } from "vuelidate/lib/validators";

  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const minDate = new Date(today)

  export default {
    name: 'TaskEdit',
    props: {
      caption: {
        type: String,
        default: 'Task '
      },
    },
    mixin: [validationMixin],
    data: () => {
      return {
        quote: null,
        model: {
          user: null,
          status: null,
          due_date: null
        },
        min: minDate,
        options: {}
      }
    },
    validations() {
      return {
        model: {
          user: {
              required
          },
          status: {
            required
          },
          due_date: {
            required
          }
        }
      }
    },
    methods: {
      validateState(name) {

        if (this.$v.model[name]) {
          const {$dirty, $error} = this.$v.model[name];
          return $dirty ? !$error : null;
        }
      },
      goBack() {
        this.$router.go(-1)
      },
      getQuote() {

        let url = '/tasks/' + this.$route.params.id + '/';

        axios.get(url).then(
          response => {
            this.quote = response.data
            this.options.users = response.data.options.users
            this.options.status = response.data.options.status
            this.model.user = response.data.assigned_to
            this.model.due_date = response.data.due_date_formatted
            this.model.status = response.data.status
          }
        ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        return this.quote
      },
      Submit() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {
          return
        } else {
          // Valid

          let url = '/tasks/' + this.$route.params.id + '/';

          var post_data = {
            'assigned_to': this.model.user,
            'status': this.model.status,
            'due_date': this.model.due_date
          }

          axios.put(url, post_data).then(
            response => {
              console.log(response.data)
              this.error = false;
              this.$router.replace({ name: 'Task', params: { id: this.quote.id } })
            }
          ).catch(error => {
              console.log('post error');
              console.log(error.response);

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
          })

        }
      }
    },
    mounted() {
      this.getQuote()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
